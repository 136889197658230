import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from "react";
import {Box, Text, Tooltip, Button, useToast} from "@chakra-ui/react";
import {nanoid} from "nanoid";
import Swal from "sweetalert2";
import {MdModeEditOutline, MdDeleteForever, MdEmail} from "react-icons/md";
import {crud, addDoctor, url, jwtDecode} from "../crudRequests";
import TriggerInfoSearch from "./TriggerInfoSearch";
import {BiEdit} from "react-icons/bi";
import {BsInfoCircleFill} from "react-icons/bs";
import Signature from "./doctors/Signature";

import CompensationRate from "./Clinicians/CompensationRate";
import axios from "axios";

export default function CliniciansTable({state, dispatch}) {
  const allClinicians = useRef([]);
  const [prevClinicians, setPrevClinicians] = useState(state.doctors);
  const [searchValue, setSearchValue] = useState("");
  const [clinicians, setClinicians] = useState(state.doctors || []);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [locationsMap, setLocationsMap] = useState({});
  const searchRef = useRef(null);
  const searchText = useRef("");
  const toast = useToast();
  if (prevClinicians !== state.doctors) {
    setPrevClinicians(state.doctors);
    searchValue ? search(searchValue) : setClinicians(state.doctors);
    if (selectedClinician) {
      let l = state.doctors?.find((d) => d.did === selectedClinician.did);
      if (l) setSelectedClinician(l);
    }
  }

  let insurersMap = useMemo(() => {
    return new Map(state.insurers?.map((i) => [i.iid, i]) || []);
  }, state.insurers);

  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  useLayoutEffect(() => {
    let getLocations = async () => {
      crud(state, [
        {
          db: state.db,
          collection: "locations",
          parameters: [{}, {lid: 1, name: 1}],
          method: "find",
        },
      ])
        .then((e) => {
          if (e.data[0]?.length > 0) {
            let map = e.data[0].reduce((ac, e) => {
              ac[e.lid] = e;
              return ac;
            }, {});
            setLocationsMap(map);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getLocations();
  }, []);

  function search(s) {
    function update(clinician) {
      if (s.length === 0) {
        return clinician;
      } else if (clinician["name"].toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["email"]?.toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["registrationId"]?.toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["licenseNumber"]?.toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["disorders"].join(" ").toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["insurances"].join(" ").toLowerCase().includes(s)) {
        return clinician;
      } else if (clinician["lid"].join(" ").toLowerCase().includes(s)) {
        return clinician;
      } else {
        return null;
      }
    }

    let filteredClinician = state.doctors.filter(update);
    setSearchValue(s);
    setClinicians(filteredClinician);
  }

  return (
    <>
      {showModal && (
        <AddOrEditClinicianModal
          state={state}
          dispatch={dispatch}
          selectedClinician={selectedClinician}
          setSelectedClinician={setSelectedClinician}
          setShowModal={setShowModal}
        />
      )}

      <div className="relative p-8 pb-4 pt-5 w-full h-full flex flex-col patients-table-container">
        <div className="bg-off relative mx-4  bg-opacity-25 text-opacity-100 flex items-center rounded-full">
          <span
            className="text-xl text-off absolute left-3"
            ref={searchRef}
            onClick={() => {
              searchRef.current.className = "hidden";
              searchText.current.focus();
            }}
          >
            <span className="font-semibold">Search clinicians</span>{" "}
            <span className="font-light">
              by any field<span className="font-semibold">...</span>
            </span>
          </span>
          <span className="text-xl text-off absolute right-2">
            <img src="/images/Doctor/search_icon.png" alt="" className="w-6" />
          </span>
          <input
            type="text"
            className="w-full bg-off p-3 px-4 bg-opacity-0 text-off rounded-full focus:outline-none  ring-[#8F8CFF] focus:ring-2 focus:ring-[#8F8CFF]"
            ref={searchText}
            onChange={() => search(searchText.current.value.toLowerCase())}
            onFocus={() => {
              searchRef.current.className = "hidden";
            }}
            onBlur={() => {
              if (!searchText.current.value) {
                setTimeout(() => {
                  if (searchRef.current) {
                    searchRef.current.className =
                      "text-xl text-off absolute left-3";
                  }
                }, 100);
              }
            }}
          />
        </div>
        <TriggerInfoSearch text="Currently, clinicians records are searchable on this page by a clinitians's first name, last name, registration number disorders, insurance, location and email." />

        <div className="flex mt-4">
          <button
            className={
              "cursor-pointer focus:outline-none  text-sm bg-background ring-[3px] ring-dark text-dark px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setClinicians(state.doctors);
              setSearchValue("");
              searchText.current.value = "";
              setTimeout(() => {
                searchRef.current.className =
                  "text-xl text-off absolute left-3";
              }, 100);
            }}
          >
            <span> Clear </span>
          </button>
          <button
            className={
              "cursor-pointer text-sm ring-[3px] ring-dark bg-dark text-background px-4 py-1.5 rounded-md m-4 font-semibold"
            }
            onClick={() => {
              setSelectedClinician("");
              setShowModal(true);
            }}
          >
            <span> Add a clinician </span>
          </button>
        </div>
        <div
          className="rounded-md m-4 mt-2 py-2 px-5 text-sm text-background font-medium text-center"
          style={{backgroundColor: "#8F8CFF"}}
        >
          <ol className="grid grid-cols-7 gap-3 text-center align-middle sm:text-[13px] ml-2 lg:text-base">
            <li></li>
            <li>Name</li>
            <li>E-mail</li>
            <li>Location</li>
            <li>Preferred Disorders</li>
            <li>Preferred insurances</li>
            <li>Actions</li>
          </ol>
        </div>
        <div className="overflow-hidden flex justify-center">
          <div className="overflow-y-scroll  w-full">
            {clinicians.length !== 0 ? (
              [...clinicians]
                ?.sort((p1, p2) => {
                  let name1 =
                    `${p1.lastName}, ${p1.firstName}`.toLocaleLowerCase(
                      "en-CA"
                    );
                  let name2 =
                    `${p2.lastName}, ${p2.firstName}`.toLocaleLowerCase(
                      "en-CA"
                    );
                  if (name1 < name2) {
                    return -1;
                  }
                  if (name1 > name2) {
                    return 1;
                  }
                  return 0;
                })
                .map((doctor, idx) => {
                  let insurersName = doctor.insurances
                    .map((i) => {
                      let ins = insurersMap.get(i);
                      return ins?.name || "";
                    })
                    .filter((e) => e)
                    .join(", ");
                  return (
                    <div
                      key={idx}
                      className="rounded-md cursor-pointer m-4 mt-0 py-3 px-5 text-sm text-dark text-opacity-75 font-medium text-center h-fit"
                      style={{backgroundColor: "#DFDEFF"}}
                      onClick={() => {
                        setSelectedClinician(doctor);
                        setShowModal(true);
                      }}
                    >
                      <ol className="grid grid-cols-7 gap-4 place-items-center  text-xs md:text-sm align-middle text-center">
                        <li>
                          {doctor.photo ? (
                            <img
                              src={doctor.photo}
                              alt="profilePhoto"
                              className="w-16 h-16 rounded-full"
                            />
                          ) : (
                            <img
                              src={"images/Admin/DoctorInput.png"}
                              alt="profilePhoto"
                              className="relative  w-16 h-16 rounded-full "
                            />
                          )}
                        </li>
                        <li className="w-full">
                          <TruncatedTextWithTooltip>
                            {`${doctor.lastName}, ${doctor.firstName}`}
                          </TruncatedTextWithTooltip>
                        </li>
                        <li className="w-full">
                          <TruncatedTextWithTooltip>
                            {doctor.email}
                          </TruncatedTextWithTooltip>
                        </li>
                        <li className="w-full">
                          <TruncatedTextWithTooltip>
                            {doctor.lid.map((id, i) => {
                              return (
                                <span key={i}>
                                  {locationsMap[id]?.name || ""}
                                  <br />
                                </span>
                              );
                            })}
                          </TruncatedTextWithTooltip>
                        </li>
                        <li className="w-full">
                          {doctor.disorders.join(", ") ? (
                            <TruncatedTextWithTooltip>
                              {doctor.disorders.join(", ")}
                            </TruncatedTextWithTooltip>
                          ) : (
                            "-"
                          )}
                        </li>
                        <li className="w-full">
                          {insurersName ? (
                            <TruncatedTextWithTooltip>
                              {insurersName}
                            </TruncatedTextWithTooltip>
                          ) : (
                            "-"
                          )}
                        </li>

                        <li>
                          <button
                            className="font-bold text-xl"
                            onClick={(e) => {
                              e.stopPropagation();
                              Swal.fire({
                                title: `Are you sure you want to remove this clinician?`,
                                html: `This clinician will be permanently removed.`,
                                icon: "warning",
                                showCancelButton: true,
                              }).then(async (value) => {
                                if (value.isConfirmed) {
                                  await crud(state, [
                                    {
                                      db: state.db,
                                      collection: "doctors",
                                      parameters: [{did: doctor.did}],
                                      method: "deleteOne",
                                    },
                                  ])
                                    .then((e) => {
                                      dispatch({
                                        type: "DELETE_DOCTOR",
                                        payload: doctor.did,
                                      });
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                      //if (error.code !== "ERR_CANCELED") window.location.href = "../login"
                                    });
                                }
                              });
                            }}
                          >
                            <span>
                              <MdDeleteForever />
                            </span>
                          </button>
                        </li>
                      </ol>
                    </div>
                  );
                })
            ) : (
              <span className="ml-5 text-sm text-off">
                No clinicians to display
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const AddOrEditClinicianModal = ({
  state,
  dispatch,
  selectedClinician,
  setSelectedClinician,
  setShowModal,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");
  const toast = useToast();
  const defaultData = useMemo(() => {
    let did = nanoid(10);
    return {
      did,
      oid: state.admin?.oid || "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      registrationId: "",
      licenseNumber: "",
      photo: "",
      unavailable: {},
      lid: state.selectedLocation ? [state.selectedLocation] : [],
      title: "",
      accreditations: "",
      signature: "",
      disorders: [],
      insurances: [],
      providerType: "",
      supervisor: did,
      access: {
        patientName: true,
        patientAge: true,
        patientGender: true,
        patientEmail: true,
        patientPhone: true,
        patientAddress: true,
        instantMessaging: true,
        transferPatient: true,
        bookAppointment: true,
        documents: true,
        notes: true,
        allPatients: false,
      },
      services: [],
      smOffered: "",
      education: "",
      desiredHperW: "",
      practitionerNumber: "",
      ontarioGroupNumber: "",
      ontarioSpecialty: "",
      ontarioMasterNumber: "",
      ontarioServiceLocationIndicator: "",
      compensationRate: {},
    };
  }, []);

  const [fields, setFields] = useState(() => {
    let data = selectedClinician
      ? {...defaultData, ...selectedClinician}
      : defaultData;
    if (typeof data.compensationRate !== "object") data.compensationRate = {};

    let obj = {};
    data.services.forEach((s) => (obj[s] = data.compensationRate[s] || 0));
    data.compensationRate = obj;

    return data;
  });
  const [insurances, setInsurances] = useState(new Set(fields.insurances));
  const [doctorLocations, setDoctorLocations] = useState(new Set(fields.lid));

  const [warning, setWarning] = useState("");
  let {_id, ...payload} = fields;
  payload.name = payload.firstName + " " + payload.lastName;
  payload.insurances = [...insurances];
  payload.lid = [...doctorLocations];
  payload.supervisor = payload.supervisor || selectedClinician?.did || "";

  function validate() {
    return !!(fields.firstName && fields.lastName && fields.email);
  }

  const addOrUpdate = async () => {
    try {
      if (validate()) {
        setWarning("");
        setLoading(true);
        if (!selectedClinician) {
          await addDoctor(state, payload);
          dispatch({type: "NEW_DOCTOR", payload});
          setLoading(false);
          setSelectedClinician("");
          setShowModal(false);
        } else {
          await crud(state, [
            {
              db: state.db,
              collection: "doctors",
              parameters: [{did: payload.did}, {$set: payload}],
              method: "updateOne",
            },
          ]);
          dispatch({type: "UPDATE_DOCTOR", payload});
          setLoading(false);
          setSelectedClinician("");
          setShowModal(false);
        }
      } else
        toast({
          title: "Please complete the mandatory fields!",
          description: "Mandatory fields must be completed!",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
    } catch (error) {
      setLoading(false);
      setSelectedClinician("");
      setShowModal(false);
      console.log(error);
    }
  };

  const resendWelcomeEmail = async () => {
    try {
      await axios.post(
        `${url}/clinicians/${selectedClinician.did}/resend-welcome-email`,
        {
          id: jwtDecode(state.jwt).id,
          db: state.db,
          org: state.organization,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.jwt || "",
          },
        }
      );

      toast({
        title: "Welcome email resent",
        description: "The welcome email has been resent to the clinician.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error resending welcome email:", error);
      toast({
        title: "Error",
        description: "Failed to resend welcome email. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <div
      className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
      style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className="relative flex max-h-[800px] h-[90vh] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[700px]"
        onClick={(e) => {
          e.stopPropagation();
          setWarning("");
        }}
      >
        {warning && <Warning warning={warning} setWarning={setWarning} />}
        <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
          <div className="bg-[#f6f5ff] mx-2 text-left  px-4 pt-10 rounded-xl overflow-hidden relative">
            {/* close button */}
            <button
              type="button"
              className="- text-gray-400  bg-transparent  absolute top-5 right-5 rounded-lg text-sm   inline-flex items-center cursor-pointer focus:outline-none"
              data-modal-toggle="small-modal"
              style={{boxShadow: "none"}}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>

            <h3
              className="text-2xl font-medium px-5 mb-3 text-gray-800"
              style={{color: "rgb(192, 191, 255)"}}
            >
              {!selectedClinician ? "Add Clinician" : "Edit Clinician"}
            </h3>

            <ProfilePhoto fields={fields} setFields={setFields} />

            {selectedClinician && (
              <Box
                p={4}
                borderRadius="md"
                mb={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  leftIcon={<MdEmail />}
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  onClick={resendWelcomeEmail}
                >
                  Resend Welcome Email
                </Button>
              </Box>
            )}

            <div className="h-full mt-3">
              <div className="overflow-hidden grid grid-cols-2 gap-3  rounded-md pb-2">
                <label className="block px-2 my-1" htmlFor="firstName">
                  <span className="text-[13px] ml-2  text-off ">
                    Clinician First Name*
                  </span>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={fields.firstName}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          firstName: e.target.value,
                        };
                      });
                    }}
                    className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  />
                </label>
                <label className="block px-2 my-1" htmlFor="lastName">
                  <span className="text-[13px] ml-2  text-off ">
                    Clinician Last Name*
                  </span>
                  <input
                    name="lastName"
                    id="lastName"
                    value={fields.lastName}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          lastName: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <label className="block px-2 my-1" htmlFor="email">
                  <span className="text-[13px] ml-2  text-off ">
                    Clinician Email*
                  </span>
                  <input
                    name="email"
                    id="email"
                    value={fields.email}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          email: e.target.value,
                        };
                      });
                    }}
                  />
                </label>

                <label className="block px-2 my-1" htmlFor="phone">
                  <span className="text-[13px] ml-2  text-off ">
                    Clinician Phone Number
                  </span>
                  <input
                    id="phone"
                    name="phone"
                    value={fields.phone}
                    className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      if (
                        e.target.value.match(
                          /^(\+|\+?\d+|\d+|^$|(\+?\d+-\d*)*)$/
                        )
                      )
                        setFields((prev) => {
                          return {
                            ...prev,
                            phone: e.target.value,
                          };
                        });
                    }}
                  />
                </label>
                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="title"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Clinician Title
                  </span>
                  <input
                    name="title"
                    id="title"
                    value={fields.title}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          title: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="accreditations"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Accreditations
                  </span>
                  <input
                    name="accreditations"
                    id="accreditations"
                    value={fields?.accreditations}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          accreditations: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <label
                  className="inline-block px-2 my-1 w-full  "
                  htmlFor="licenseNumber"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    License Number
                  </span>
                  <input
                    name="licenseNumber"
                    id="licenseNumber"
                    value={fields.licenseNumber}
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          licenseNumber: e.target.value,
                        };
                      });
                    }}
                  />
                </label>
                <div className="flex items-center justify-between">
                  <label
                    className="inline-block px-2 my-1 w-full  "
                    htmlFor="registrationId"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      Registration Number (CPSO)
                    </span>
                    <input
                      name="registrationId"
                      id="registrationId"
                      value={fields.registrationId}
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            registrationId: e.target.value,
                          };
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="flex items-center justify-between">
                  <label
                    className="inline-block px-2 my-1 w-full  "
                    htmlFor="smOffered"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      Services/modalities offered
                    </span>
                    <input
                      name="smOffered"
                      id="smOffered"
                      value={fields?.smOffered}
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            smOffered: e.target.value,
                          };
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="flex items-center justify-between">
                  <label
                    className="inline-block px-2 my-1 w-full  "
                    htmlFor="education"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      Clinician education
                    </span>
                    <input
                      name="education"
                      id="education"
                      value={fields?.education}
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        setFields((prev) => {
                          return {
                            ...prev,
                            education: e.target.value,
                          };
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="flex items-center justify-between">
                  <label
                    className="inline-block px-2 my-1 w-full  "
                    htmlFor="desiredHperW"
                  >
                    <span className="text-[13px] ml-2  text-off ">
                      Desired hours/week
                    </span>
                    <input
                      name="desiredHperW"
                      id="desiredHperW"
                      type="text"
                      value={fields?.desiredHperW}
                      className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^\d+$/g) ||
                          e.target.value === ""
                        )
                          setFields((prev) => {
                            return {
                              ...prev,
                              desiredHperW: parseInt(e.target.value || 0),
                            };
                          });
                      }}
                    />
                  </label>
                </div>

                <label
                  className="inline-block px-2 my-1 w-full"
                  htmlFor="providerType"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Provider Types
                  </span>
                  <select
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          providerType: e.target.value,
                        };
                      });
                    }}
                    value={fields?.providerType || ""}
                    name="providerType"
                    id="providerType"
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  >
                    <option value="">-</option>
                    {state.providerTypes.map((p, i) => (
                      <option key={i} value={p.typeId}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label
                  className="inline-block px-2 my-1 w-full"
                  htmlFor="supervisor"
                >
                  <span className="text-[13px] ml-2  text-off ">
                    Supervisor
                  </span>
                  <select
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          supervisor: e.target.value,
                        };
                      });
                    }}
                    value={fields?.supervisor || ""}
                    name="supervisor"
                    id="supervisor"
                    className=" w-full block py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  >
                    <option value="">-</option>
                    {(state.doctors || [state.doctor]).map((p, i) => (
                      <option key={i} value={p.did}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label className="block mx-3.5 my-1">
                  <span className="text-[13px] ml-2  text-off ">
                    Description
                  </span>
                  <textarea
                    placeholder=" Write a brief description..."
                    value={fields.description || ""}
                    onChange={(e) => {
                      setFields((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                    className="h-[4.5rem] block placeholder:font-normal w-[17rem] placeholder:opacity-50 px-3 py-2.5 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                  />
                </label>
                {Object.keys(fields.compensationRate).length > 0 && (
                  <CompensationRate
                    {...{
                      state,
                      clinician: fields,
                      setFields,
                      entries: fields.compensationRate,
                    }}
                  />
                )}
              </div>

              <div className="px-2 pr-0 w-full my-5 pb-1 flex  flex-col text-left items-start">
                <span className="text-[13px] ml-2 mb-1 font-semibold text-off">
                  ClinicAid Provider Information (if applicable)
                </span>
                <div className="flex-1 w-full  flex space-y-2  border overflow-hidden rounded-xl p-1 pb-2">
                  <div className="overflow-hidden w-full grid grid-cols-2 gap-3   rounded-md pb-2">
                    <label
                      className="block px-2 my-1"
                      htmlFor="practitionerNumber"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Practitioner/Billing Number
                      </span>
                      <input
                        type="text"
                        id="practitionerNumber"
                        value={fields.practitionerNumber || ""}
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              practitionerNumber: e.target.value,
                            };
                          });
                        }}
                        className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                      />
                    </label>
                    <label
                      className="block px-2 my-1"
                      htmlFor="ontarioGroupNumber"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Group Number
                      </span>
                      <input
                        name="ontarioGroupNumber"
                        id="ontarioGroupNumber"
                        value={fields.ontarioGroupNumber || ""}
                        className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              ontarioGroupNumber: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                    <label
                      className="block px-2 my-1"
                      htmlFor="ontarioSpecialty"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Specialty
                      </span>
                      <input
                        name="ontarioSpecialty"
                        id="ontarioSpecialty"
                        value={fields.ontarioSpecialty || ""}
                        className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              ontarioSpecialty: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>

                    <label
                      className="block px-2 my-1"
                      htmlFor="ontarioMasterNumber"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Master Number
                      </span>
                      <input
                        name="ontarioMasterNumber"
                        id="ontarioMasterNumber"
                        value={fields.ontarioMasterNumber || ""}
                        className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              ontarioMasterNumber: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                    <label
                      className="block px-2 my-1"
                      htmlFor="ontarioServiceLocationIndicator"
                    >
                      <span className="text-[13px] ml-2  text-off ">
                        Service Location Indicator
                      </span>
                      <input
                        name="ontarioServiceLocationIndicator"
                        id="ontarioServiceLocationIndicator"
                        value={fields.ontarioServiceLocationIndicator || ""}
                        className=" block w-full py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
                        onChange={(e) => {
                          setFields((prev) => {
                            return {
                              ...prev,
                              ontarioServiceLocationIndicator: e.target.value,
                            };
                          });
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mx-3.5">
                <Signature
                  schState={state}
                  dispatch={dispatch}
                  doctor={fields}
                  setSignature={(value) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        signature: value,
                      };
                    });
                  }}
                />
              </div>
              <AssignedLocations
                locations={state.locations}
                doctorLocations={doctorLocations}
                setDoctorLocations={setDoctorLocations}
              />
              <PreferredInsurances
                insurers={state.insurers}
                insurances={insurances}
                setInsurances={setInsurances}
              />

              <MultipleEntriesInput
                label="Preferred Disorders"
                disorders={fields.disorders}
                setFields={setFields}
                fieldName={"disorders"}
              />
            </div>
            <label className="block px-2 my-1" htmlFor="zoomLink">
              <span className="text-[13px] ml-2  text-off ">Zoom link</span>
              <input
                type="text"
                name="zoomLink"
                id="zoomLink"
                value={fields.zoomLink}
                onChange={(e) => {
                  setFields((prev) => {
                    return {
                      ...prev,
                      zoomLink: e.target.value,
                    };
                  });
                }}
                className=" block w-full border-transparent py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
              />
            </label>
            {errorMessage && (
              <div
                className="bg-red-100 border border-red-400 text-red-700  py-3 px-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Error!</strong>
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}

            <div className="mt-6 w-4/5 mb-4 mx-auto">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addOrUpdate();
                }}
                disabled={loading}
                className=" w-full rounded-lg flex justify-center items-center text-background bg-med py-2"
              >
                {!selectedClinician ? "Add Clinician" : "Save changes"}
                {loading && (
                  <span className="">
                    <svg
                      className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25 stroke-[4px]"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function MultipleEntriesInput({disorders, setFields, label, fieldName}) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      const newEntries = new Set([...disorders, inputValue.trim()]);
      setInputValue("");
      setFields((prev) => {
        return {
          ...prev,
          [fieldName]: [...newEntries],
        };
      });
    }
  };

  return (
    <div className="space-y-2">
      <label className="relative block my-1 text-left" htmlFor="lastName">
        <span className="text-[13px] ml-2  text-off  ">{label}</span>
        <input
          name="lastName"
          id="lastName"
          className=" block w-full peer  py-3 px-3 text-sm text-off font-medium bg-white  rounded-2xl focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-40"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
        />
        <div className="hidden w-full absolute top-[0px] right-2  text-[10px] mt-1 text-yellow-500 peer-focus:flex justify-end items-center space-x-1">
          <span className="">{"Type an option and press enter."}</span>
          <BsInfoCircleFill />
        </div>
      </label>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[80px]  w-full sbar2   p-2">
          {disorders.map((entry, index) => (
            <li
              key={index}
              className="inline-block mx-1 my-1  py-1 text-sm text-white font-medium bg-[#ACAAFF] rounded-md cursor-pointer relative px-4"
              onClick={() => {
                setFields((prev) => {
                  return {
                    ...prev,
                    [fieldName]: prev[fieldName].filter((f) => f !== entry),
                  };
                });
              }}
            >
              <svg
                aria-hidden="true"
                className="w-2 h-2 absolute to left-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="">{entry}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function PreferredInsurances({insurances, setInsurances, insurers, creating}) {
  return (
    <div className="px-2 pr-0 w-full pb-1 flex  flex-col text-left items-start">
      <span className="text-[13px] ml-2 text-off">Preferred Insurances</span>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[160px]  w-full sbar2   p-2">
          {insurers.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating) {
                  !insurances?.has(crit.iid)
                    ? setInsurances((prev) => {
                        let n = new Set(prev);
                        n.add(crit.iid);
                        return n;
                      })
                    : setInsurances((prev) => {
                        let n = new Set(prev);
                        n.delete(crit.iid);
                        return n;
                      });
                }
              }}
              key={i}
              className="hover:cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1"
            >
              <span
                className=" h-5 w-5 rounded-md shadow-ntf bg-white hover:cursor-pointer"
                style={{
                  backgroundColor: insurances.has(crit.iid)
                    ? "#9F9DFA"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                {crit.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function AssignedLocations({
  doctorLocations,
  setDoctorLocations,
  locations,
  creating,
}) {
  return (
    <div className="px-2 pr-0 w-full my-4 pb-1 flex  flex-col text-left items-start">
      <span className="text-[13px] ml-2 text-off">Locations</span>
      <div className="flex-1 w-full  flex space-y-2  shadow-inner overflow-hidden rounded-xl p-1 pb-2">
        <ul className="overflow-y-scroll rounded-xl content-start flex  items-start flex-wrap  max-h-[160px]  w-full sbar2   p-2">
          {locations.map((crit, i) => (
            <li
              onClick={() => {
                if (!creating) {
                  !doctorLocations?.has(crit.lid)
                    ? setDoctorLocations((prev) => {
                        let n = new Set(prev);
                        n.add(crit.lid);
                        return n;
                      })
                    : setDoctorLocations((prev) => {
                        let n = new Set(prev);
                        n.delete(crit.lid);
                        return n;
                      });
                }
              }}
              key={i}
              className="hover:cursor-pointer m-1 flex items-center  p-1 py-0 px-0 space-x-1"
            >
              <span
                className=" h-5 w-5 rounded-md shadow-ntf bg-white hover:cursor-pointer"
                style={{
                  backgroundColor: doctorLocations.has(crit.lid)
                    ? "#9F9DFA"
                    : "#FFFFFF",
                }}
              ></span>

              <span className="font-medium text-[0.9rem] text-[#8e8df9]">
                {crit.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function ProfilePhoto({fields, setFields}) {
  const fileRef = useRef(null);
  function onUpdatePhoto(ele) {
    let files = ele.target.files;
    let file = files[0];
    if (!file?.type.match(/image.*/i)) return;
    var reader = new FileReader();

    reader.addEventListener("load", (e) => {
      setFields((prev) => ({...prev, photo: e.target.result}));
    });
    reader.readAsDataURL(file);
  }
  return (
    <div className="flex justify-center items-center">
      <div className="h-[70px] w-[70px] rounded-full shadow-lg flex justify-center items-center overflow-hidden relative">
        <input
          ref={fileRef}
          onChange={(e) => {
            onUpdatePhoto(e);
          }}
          className="absolute top-0 opacity-0 z-0"
          type="file"
          name="updatePhoto"
        />
        {fields?.photo ? (
          <img
            src={fields.photo}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full rounded-full"
          />
        ) : (
          <img
            src={"images/Admin/DoctorInput.png"}
            alt=""
            className="relative z-10 hover:cursor-pointer  h-full rounded-full "
          />
        )}
        <div
          className="absolute group transition text-transparent hover:text-white text-lg inset-0 h-full w-full rounded-full z-20 flex justify-center items-center hover:bg-gray-400/50 hover:cursor-pointer"
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <BiEdit className="" />
        </div>
      </div>
    </div>
  );
}

function Warning({warning, setWarning, className}) {
  return (
    <div
      className={
        className ||
        "show z-10 absolute top-3 left-3 text-xs bg-yellow-500 p-3 w-48 rounded-md"
      }
    >
      <div className="font-bold flex">
        <span className="flex text-white items-center space-x-1">
          <img
            alt=""
            src="/images/Clinic/warn.png"
            className="h-4  inline-block"
          />{" "}
          <span> Warning:</span>
        </span>
        <span
          className="ml-auto mr-1 text-sm text-white underline cursor-pointer font-light"
          onClick={(e) => setWarning("")}
        >
          x
        </span>
      </div>
      <div className="font-regular mt-1 text-white">{warning}</div>
    </div>
  );
}

export function TruncatedTextWithTooltip({children, ...props}) {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [children]);

  return (
    <Tooltip label={children} isDisabled={!isTruncated}>
      <Text ref={textRef} isTruncated {...props}>
        {children}
      </Text>
    </Tooltip>
  );
}
